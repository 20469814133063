import { Col, Row, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { SensogramPanel } from '../../components/graphic/sensogram/SensogramPanel';
import { IntensityPanel } from '../../components/graphic/intensity/Intensity';
import { PCAPanel } from '../../components//graphic/pca';
import CardSection from '../../components/section/CardSection';
import { selectCurrentRecordID, selectRecords } from '../../features/analysisConfig/analysisConfigSlice';
import { SessionRunsTable } from './DashboardRuns';
import Matrix from '../../components/graphic/matrix/Matrix';
import { selectVisibleSettings } from '../../features/analysisConfig/sessionInfoSlice';
import { SignaturePanel } from '../../components/graphic/signature/SignaturePanel';

type DashboardMainProps = {
  isExpendedMenu: boolean;
};

const DashboardMain: React.FC<DashboardMainProps> = (props) => {
  const { sessionID } = useParams<{ sessionID: string }>();
  const selectedRecordID = useAppSelector(selectCurrentRecordID);

  const records = useAppSelector(selectRecords);
  const visibleSettings = useAppSelector(selectVisibleSettings);

  if (selectedRecordID < -1) {
    return null;
  }

  if (sessionID === undefined || selectedRecordID < -1) {
    return null;
  }

  return (
    <Col id="dashbord-main" style={{ marginLeft: props.isExpendedMenu ? 350 : 70, width: props.isExpendedMenu ? 'calc(100% - 350px)' : 'calc(100% - 70px)' }}>
      <CardSection scroll="hidden">
        <Row justify="space-between" gutter={[20, 20]}>
          <Col xl={visibleSettings ? 24 : 16} xxl={16} span={24}>
            <div id="sensogram-panel-div">{records === undefined || records.length === 0 ? <Skeleton active /> : <SensogramPanel />}</div>
          </Col>
          <Col xl={visibleSettings ? 24 : 8} xxl={8} span={24}>
            <div id="signature-panel-div">{records === undefined || records.length === 0 ? <Skeleton active /> : <SignaturePanel sessionID={sessionID} />}</div>
          </Col>
        </Row>
      </CardSection>
      <Row justify="space-between" gutter={[20, 10]} style={{ marginTop: '10px' }}>
        <Col xl={visibleSettings ? 24 : 12} xxl={12} span={24}>
          <CardSection>
            <div id="pca-panel-div" style={{ height: '50vh' }}>
              {records === undefined || records.length === 0 ? <Skeleton active /> : <PCAPanel sessionID={sessionID} />}
            </div>
          </CardSection>
        </Col>
        <Col xl={visibleSettings ? 24 : 12} xxl={12} span={24}>
          <CardSection>
            <div id="intensity-panel-div" style={{ height: '50vh' }}>
              {records === undefined || records.length === 0 ? <Skeleton active /> : <IntensityPanel sessionID={sessionID} />}
            </div>
          </CardSection>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[20, 10]} style={{ marginTop: '10px' }}>
        <Col xl={visibleSettings ? 24 : 12} xxl={12} span={24}>
          <CardSection>
            <div id="dm-panel-div" style={{ height: '50vh' }}>
              {records === undefined || records.length === 0 ? <Skeleton active /> : <Matrix sessionID={sessionID} />}
            </div>
          </CardSection>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[20, 10]} style={{ marginTop: '10px' }}>
        <Col xl={24} span={24}>
          <CardSection>
            <div id="runs-table-panel-div">{records === undefined || records.length === 0 ? <Skeleton active /> : <SessionRunsTable sessionID={sessionID} />}</div>
          </CardSection>
        </Col>
      </Row>
    </Col>
  );
};

export default DashboardMain;
