import { MenuFoldOutlined, MenuUnfoldOutlined, OrderedListOutlined, ShrinkOutlined, UnorderedListOutlined, VerticalAlignBottomOutlined, WarningFilled } from '@ant-design/icons';
import { Checkbox, Input, Row, Skeleton, Space, Tag, Tooltip, Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { useCallback, useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CardSection from '../../../components/section/CardSection';
import { defaultColorPalette, getColormap } from '../../../compute/colormap';
import { getRecordNameShort, renderColoredTag, spots2peptides, getRecordNameOneLine } from '../../../compute/utils';
import {
  selectColormap,
  selectCurrentItemName,
  selectCurrentRecordID,
  selectExcludedRecordIDs,
  selectRecords,
  selectSubtractItemName,
  setColormap,
  setCurrentItemName,
  setCurrentRecordID,
  setExcludedRecordIDs,
  setRecords,
  setSensogramRenderType,
} from '../../../features/analysisConfig/analysisConfigSlice';
import { AryRecord, SensogramRenderType } from '../../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';

type DashbordMeasuresProps = {
  setIsExpandedMenu: React.Dispatch<React.SetStateAction<boolean>>;
};
const DashbordMeasures: React.FC<DashbordMeasuresProps> = (props) => {
  const { authState } = useOktaOrQueryAuth();

  const { sessionID } = useParams<{ sessionID: string }>();

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [filterText, setFilterText] = useState<string>('');

  const [isExpandedAll, setIsExpandedAll] = useState(true);
  const [isShowFullNames, setIsShowFullNames] = useState(false);

  const [isPeptideSetIncoherent, setIsPeptideSetIncoherent] = useState(false);

  const [treeData, setTreeData] = useState<DataNode[]>([]);

  const subtractedItemName = useAppSelector(selectSubtractItemName);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const dispatch = useAppDispatch();

  const currentRecordID = useAppSelector(selectCurrentRecordID);
  const currentItemName = useAppSelector(selectCurrentItemName);

  const cmap = useAppSelector(selectColormap);
  const records = useAppSelector(selectRecords);

  const getNodeTitle = useCallback((record: AryRecord) => (isShowFullNames ? getRecordNameOneLine(record) : getRecordNameShort(record)), [isShowFullNames]);

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/list_records?session_id=${sessionID}&ts=${Date.now()}`, authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((records: AryRecord[]) => {
        if (records === null) {
          return;
        }

        var checkedIDs: number[] = [];
        records.forEach((record) => {
          if (!excludedRecordIDs.includes(record.ID)) {
            checkedIDs.push(record.ID);
          }
        });
        let checked: React.Key[] = checkedIDs;
        setCheckedKeys(checked);

        const uniqueLabels = Array.from(new Set(records.map((r) => r.ItemName)));
        uniqueLabels.sort();
        let cmap = getColormap(uniqueLabels);
        dispatch(setColormap(cmap));
        dispatch(setRecords(records));
      });
  }, [authState, excludedRecordIDs, sessionID, currentRecordID]);

  useEffect(() => {
    if (records === undefined || records === null) {
      return;
    }
    var checkedIDs: number[] = [];
    records.forEach((record) => {
      if (!excludedRecordIDs.includes(record.ID)) {
        checkedIDs.push(record.ID);
      }
    });
    let checked: React.Key[] = checkedIDs;
    setCheckedKeys(checked);
  }, [excludedRecordIDs]);

  useEffect(() => {
    let _treeData: DataNode[] = [];
    if (records === undefined || records.length === 0 || cmap === undefined) {
      return;
    }

    // Keep track of uniquePeptideSets
    var commonUniquePeptidesNb: number = 0;
    const _records = [...records].sort((a: any, b: any) => a.ItemName.localeCompare(b.ItemName));

    _records.forEach((record) => {
      var recordTitle = getNodeTitle(record);
      var [peptideCodeStrs] = spots2peptides(record.Sensors);
      var uniquePeptideCodeStrsSet = new Set(peptideCodeStrs);
      let uniquePeptideCodeStrs = Array.from(uniquePeptideCodeStrsSet);

      if (commonUniquePeptidesNb === 0) {
        commonUniquePeptidesNb = uniquePeptideCodeStrs.length;
      }

      if (commonUniquePeptidesNb !== uniquePeptideCodeStrs.length) {
        setIsPeptideSetIncoherent(true);
      }

      var recordInfo = (
        <>
          <b>Timestamp:</b> {new Date(record.AbsoluteTimestamp * 1e3).toLocaleString()}
          <br />
          <b>Device:</b> {record.DeviceID}
          <br />
          <b>RunID:</b> {record.RunID}
          <br />
          <b>Peptides:</b> {uniquePeptideCodeStrs.length} on {record.Sensors.length} spots
        </>
      );
      var recordTitleNode = (
        <Tooltip placement="right" mouseLeaveDelay={0} mouseEnterDelay={0.1} overlay={recordInfo} overlayInnerStyle={{ minWidth: '300px' }}>
          {recordTitle}
          {record.ID === currentRecordID && <span style={{ width: 13, height: 13, marginLeft: 10, marginBottom: -2, borderRadius: '50%', background: defaultColorPalette[cmap[record.ItemName]], display: 'inline-block' }}></span>}
        </Tooltip>
      );
      var isRecordOutfiltered = false;
      if (filterText !== '') {
        let filterRe = new RegExp(filterText, 'gi');
        if (!recordTitle.match(filterRe)) {
          isRecordOutfiltered = true;
        }
      }
      for (let i = 0; i < _treeData.length; i++) {
        let node = _treeData[i];
        if (node.key === record.ItemName) {
          if (node.children === undefined) {
            node.children = [];
          }
          node.children.push({
            style: isRecordOutfiltered ? { display: 'none' } : {},
            key: record.ID,
            className: `checkbox-${cmap[record.ItemName]}`,
            title:
              filterText === '' ? (
                recordTitleNode
              ) : (
                <Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[filterText]} autoEscape textToHighlight={recordTitle}>
                  {recordTitleNode}
                </Highlighter>
              ),
          } as DataNode);
          return;
        }
      }
      _treeData.push({
        title: (
          <div>
            {renderColoredTag(
              defaultColorPalette[cmap[record.ItemName]],
              <>
                {record.ItemName}
                {subtractedItemName === record.ItemName && (
                  <Tooltip
                    overlay={
                      <>
                        Average sensogram of this item (its selected records) is subtracted from senosograms of other items
                        <br />
                        <br />
                        This item is excluded from signature analysis
                      </>
                    }
                  >
                    <VerticalAlignBottomOutlined />
                  </Tooltip>
                )}
              </>
            )}
          </div>
        ),
        className: `checkbox-${cmap[record.ItemName]}`,
        key: record.ItemName,
        children: [
          {
            key: record.ID,
            style: isRecordOutfiltered ? { display: 'none' } : {},
            className: `checkbox-${cmap[record.ItemName]}`,
            title:
              filterText === '' ? (
                recordTitleNode
              ) : (
                <Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[filterText]} autoEscape textToHighlight={recordTitle}>
                  {recordTitleNode}
                </Highlighter>
              ),
          } as DataNode,
        ],
      });
    });
    setTreeData(_treeData);
  }, [sessionID, filterText, isShowFullNames, cmap, subtractedItemName, currentRecordID, currentItemName]);

  if (records === undefined || records.length === 0) {
    return (
      <CardSection flexGrow="1" scroll="auto" marginBottom="2%">
        <Row justify="space-between" style={{ marginTop: -10 }}>
          <p style={{ fontWeight: 500, marginBottom: 10 }}>Measurements</p>
          <ShrinkOutlined className="clickable-icon" onClick={() => props.setIsExpandedMenu(false)} />
        </Row>
        <Row align="middle" justify="center" style={{ paddingTop: 100 }}>
          <Skeleton active />
          <Skeleton active />
        </Row>
      </CardSection>
    );
  }

  const uniqueItemNames = Array.from(new Set(records.map((r) => r.ItemName)));
  uniqueItemNames.sort();

  return (
    <CardSection flexGrow="1" scroll="auto" marginBottom="2%">
      <Row justify="space-between" style={{ marginTop: -10 }}>
        <p style={{ fontWeight: 500, marginBottom: 10 }}>Measurements</p>
        <ShrinkOutlined className="clickable-icon" onClick={() => props.setIsExpandedMenu(false)} />
      </Row>
      <Space direction="vertical">
        <Space align="center">
          {!isExpandedAll ? (
            <Tooltip overlay="Collapse all">
              <MenuUnfoldOutlined
                className="clickable-icon"
                style={{ display: 'flex' }}
                onClick={() => {
                  setExpandedKeys([]);
                  setIsExpandedAll(true);
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip overlay="Expand all">
              <MenuFoldOutlined
                className="clickable-icon"
                style={{ display: 'flex' }}
                onClick={() => {
                  setExpandedKeys(uniqueItemNames);
                  setIsExpandedAll(false);
                }}
              />
            </Tooltip>
          )}
          {/* Show full names or short ones */}
          {!isShowFullNames ? (
            <Tooltip overlay="Show sequence numbers">
              <OrderedListOutlined
                className="clickable-icon"
                style={{ display: 'flex' }}
                onClick={() => {
                  setIsShowFullNames(true);
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip overlay="Hide sequence numbers">
              <UnorderedListOutlined
                className="clickable-icon"
                style={{ display: 'flex' }}
                onClick={() => {
                  setIsShowFullNames(false);
                }}
              />
            </Tooltip>
          )}
          <Input
            style={{ width: '80%', borderRadius: 5, height: 25, display: 'flex' }}
            allowClear={true}
            placeholder="Records"
            onChange={(e) => {
              setFilterText(e.target.value);
              if (e.target.value !== '') {
                setExpandedKeys(uniqueItemNames);
                setIsExpandedAll(false);
              }
            }}
          />
          {isPeptideSetIncoherent ? (
            <Tooltip overlay="Incoherency in peptide sets - some features may not be available!">
              <WarningFilled style={{ color: 'orange', display: 'flex' }} className="clickable-icon" />
            </Tooltip>
          ) : null}
        </Space>
        <div style={{ display: 'flex' }}>
          <div>
            <Checkbox
              checked={checkedKeys.length !== 0}
              indeterminate={checkedKeys.length > 0 && checkedKeys.length < records.length}
              onChange={(e) => {
                if (e.target.checked) {
                  setCheckedKeys(records.map((r) => r.ID));
                } else {
                  setCheckedKeys([]);
                }
              }}
            />
          </div>
          <div style={{ marginLeft: 10, cursor: 'pointer' }}>
            <Tag
              onClick={() => {
                dispatch(setSensogramRenderType(SensogramRenderType.Dataset));
              }}
            >
              Dataset
            </Tag>
          </div>
        </div>
        <Tree
          checkable={true}
          showIcon={false}
          expandedKeys={expandedKeys}
          onExpand={(keys) => {
            setExpandedKeys(keys);
          }}
          selectedKeys={[currentRecordID]}
          onSelect={(keys, info) => {
            if (keys.length > 0) {
              let key = keys[0];
              if (typeof key.valueOf() === 'string') {
                dispatch(setSensogramRenderType(SensogramRenderType.Item));
                dispatch(setCurrentItemName(key as string));
                dispatch(setCurrentRecordID(-1));
                return;
              }
              dispatch(setSensogramRenderType(SensogramRenderType.Record));
              dispatch(setCurrentItemName(''));
              dispatch(setCurrentRecordID(key as number));
              return;
            }
          }}
          checkedKeys={checkedKeys}
          onCheck={(currentlyCheckedKeys) => {
            var _keys = currentlyCheckedKeys as React.Key[];
            var _excludedRecordIDs: number[] = [];
            setCheckedKeys(_keys);
            records.forEach((record) => {
              let key = record.ID;
              if (!_keys.includes(key)) {
                _excludedRecordIDs.push(record.ID);
              }
            });
            dispatch(setExcludedRecordIDs(_excludedRecordIDs));
          }}
          treeData={treeData}
        />
      </Space>
    </CardSection>
  );
};

export default DashbordMeasures;
