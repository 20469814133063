import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { arycolor } from '../../../assets/css/color';
import { selectRecords, selectSigmaMultiplier, setSigmaMultiplier, setSubtractItemName } from '../../../features/analysisConfig/analysisConfigSlice';

const SigmaMultiplierSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const records = useAppSelector(selectRecords);
  const sigmaMultiplier = useAppSelector(selectSigmaMultiplier);

  const uniqueItemNames = Array.from(new Set(records?.map((r) => r.ItemName)));
  uniqueItemNames.sort();

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <span>Confidence level (n-sigma)</span>
        <Popover
          style={{ display: 'flex', maxWidth: '500px' }}
          trigger={'hover'}
          placement="left"
          content={
            <div>
              <p style={{ marginBottom: 0 }}>Define the confidence level for statistical metrics. The greater the confidence level, the harder it is to discriminate stuff.</p>
            </div>
          }
        >
          <FontAwesomeIcon
            icon="info-circle"
            className="clickable-icon"
            style={{
              color: arycolor.aryBlue,
              marginLeft: '20px',
            }}
          />
        </Popover>
      </div>
      <Select
        value={sigmaMultiplier}
        style={{ width: '100%', marginTop: 10 }}
        showSearch={true}
        placeholder="Select confidence level"
        allowClear={false}
        options={[
          {
            label: '67% (1σ)',
            value: 1,
          },
          {
            label: '95% (2σ)',
            value: 2,
          },
          {
            label: '99% (3σ)',
            value: 3,
          },
        ]}
        onChange={(value: number) => {
          dispatch(setSigmaMultiplier(value));
        }}
      />
    </div>
  );
};

export default SigmaMultiplierSettings;
