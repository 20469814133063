import { useEffect, useState } from 'react';
import { useAppSelector } from '../app/hooks';
import { defaultColorPalette } from '../compute/colormap';
import { renderColoredTag } from '../compute/utils';
import { selectColormap, selectExcludedRecordIDs, selectRecords } from '../features/analysisConfig/analysisConfigSlice';
import { AryRecord } from '../types/analysisTypes';

type RecordsLegendProps = {};

const RecordsLegend: React.FC<RecordsLegendProps> = () => {
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const records = useAppSelector(selectRecords);
  const [effectiveRecordsLabels, setEffectiveLabels] = useState<string[]>();
  const cmap = useAppSelector(selectColormap);

  useEffect(() => {
    if (records != null) {
      let _effectiveRecords: AryRecord[] = [];
      records.forEach((r) => {
        if (!excludedRecordIDs.includes(r.ID)) _effectiveRecords.push(r);
      });
      const effectiveRecordsLabels = Array.from(new Set(_effectiveRecords.map((r) => r.ItemName))).sort();
      setEffectiveLabels(effectiveRecordsLabels);
    }
  }, [excludedRecordIDs, records]);

  if (cmap === undefined) return null;

  return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', marginTop: 50, flexWrap: 'wrap' }}>{effectiveRecordsLabels?.map((itemName) => renderColoredTag(defaultColorPalette[cmap[itemName]], <>{itemName}</>))}</div>;
};

export default RecordsLegend;
