import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { arycolor } from '../../../assets/css/color';
import { selectChemicalCalibrationItemNames, selectRecords, setChemicalCalibrationItemNames } from '../../../features/analysisConfig/analysisConfigSlice';

const ChemicalCalibrationSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const records = useAppSelector(selectRecords);
  const chemicalCalibrationItemNames = useAppSelector(selectChemicalCalibrationItemNames);

  const uniqueItemNames = Array.from(new Set(records?.map((r) => r.ItemName)));
  uniqueItemNames.sort();

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <span>Calibration item names</span>
        <Popover
          style={{ display: 'flex', maxWidth: '500px' }}
          trigger={'hover'}
          placement="bottomRight"
          content={
            <div>
              <p style={{ marginBottom: 0 }}>
                Records of calibration item names will be used to calibrate the dataset
                <br />
              </p>
            </div>
          }
        >
          <FontAwesomeIcon
            icon="info-circle"
            className="clickable-icon"
            style={{
              color: arycolor.aryBlue,
              marginLeft: '20px',
            }}
          />
        </Popover>
      </div>
      <Select
        value={chemicalCalibrationItemNames.length > 0 ? chemicalCalibrationItemNames : undefined}
        mode="multiple"
        style={{ width: '100%', marginTop: 10 }}
        showSearch={true}
        placeholder="Select calibrant items.."
        allowClear={true}
        options={uniqueItemNames.map((uin) => ({
          label: uin,
          value: uin,
        }))}
        onChange={(value: string[] | undefined) => {
          if (value === undefined) {
            value = [];
          }
          dispatch(setChemicalCalibrationItemNames(value));
        }}
      />
    </div>
  );
};

export default ChemicalCalibrationSettings;
