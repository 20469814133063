import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { arycolor } from '../../../assets/css/color';
import { selectRecords, selectSubtractItemName, setSubtractItemName } from '../../../features/analysisConfig/analysisConfigSlice';

const SubstractItemSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const records = useAppSelector(selectRecords);
  const subtractItemName = useAppSelector(selectSubtractItemName);

  const uniqueItemNames = Array.from(new Set(records?.map((r) => r.ItemName)));
  uniqueItemNames.sort();

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <span>Substract sensogram profile</span>
        <Popover
          style={{ display: 'flex' }}
          trigger={'hover'}
          placement="bottomRight"
          content={
            <div>
              <p style={{ marginBottom: 0 }}>
                Subtract sensogram of negative control samples to improve resolution.
                <br />
                Average sensogram of selected records of this item will be subtracted from senosgrams of all the other items
              </p>
            </div>
          }
        >
          <FontAwesomeIcon
            icon="info-circle"
            className="clickable-icon"
            style={{
              color: arycolor.aryBlue,
              marginLeft: '20px',
            }}
          />
        </Popover>
      </div>
      <Select
        value={subtractItemName ? subtractItemName : undefined}
        style={{ width: '100%', marginTop: 10 }}
        showSearch={true}
        placeholder="Select item to subtract.."
        allowClear={true}
        options={uniqueItemNames.map((uin) => ({
          label: uin,
          value: uin,
        }))}
        onChange={(value: string) => {
          if (value === undefined) {
            value = '';
          }
          dispatch(setSubtractItemName(value));
        }}
      />
    </div>
  );
};

export default SubstractItemSettings;
