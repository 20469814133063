import { ArrowsAltOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, Popover, Row, Space, Tooltip } from 'antd';
import { useState } from 'react';
import { NormType, MultiSignaturesFigureType } from '../../../types/analysisTypes';
import RecordsLegend from '../../RecordsLegend';
import FullscreenGraphicModal from '../FullscreenGraphicModal';
import { MultiSignaturesFigure } from './MultiSignaturesFigure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '../../../app/hooks';
import { selectChemicalCalibrationItemNames, selectHumidityCompensationCalibrantName } from '../../../features/analysisConfig/analysisConfigSlice';

export const MultiSignaturesWidget: React.FC = () => {
  const [figureType, setFigureType] = useState<MultiSignaturesFigureType>(+MultiSignaturesFigureType.Radar);
  const [normType, setNormType] = useState<NormType>(NormType.Normalized);
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);

  const [heatmapRangeValue, setHeatmapRangeValue] = useState<[number, number]>([0, 1]);

  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const chemicalCalibrationItemNames = useAppSelector(selectChemicalCalibrationItemNames);

  return (
    <div style={{ width: '100%', height: '40vh', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
      <Row justify="space-between" align="middle">
        <Col></Col>
        <Col>
          <Space align="center" style={{ display: 'flex', justifyContent: 'center' }}>
            <Dropdown
              overlay={
                <Menu
                  items={Object.entries(NormType)
                    .filter(([_, value]) => typeof value === 'number')
                    .map(([label, value]) => {
                      return {
                        key: +value,
                        label: label,
                        onClick: () => {
                          setNormType(+value);
                        },
                      };
                    })}
                />
              }
            >
              <Tooltip title="Normalization">
                <Button style={{ borderRadius: '5px' }}>
                  <Space>
                    {NormType[normType]}
                    <DownOutlined />
                  </Space>
                </Button>
              </Tooltip>
            </Dropdown>
            <Dropdown
              overlay={
                <Menu
                  items={Object.entries(MultiSignaturesFigureType)
                    .filter(([_, value]) => typeof value === 'number')
                    .map(([label, value]) => {
                      return {
                        key: +value,
                        label: label,
                        onClick: () => {
                          setFigureType(+value);
                        },
                      };
                    })}
                />
              }
            >
              <Tooltip title="Chart type">
                <Button style={{ borderRadius: '5px' }}>
                  <Space>
                    {MultiSignaturesFigureType[figureType]}
                    <DownOutlined />
                  </Space>
                </Button>
              </Tooltip>
            </Dropdown>
          </Space>
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          {chemicalCalibrationItemNames && chemicalCalibrationItemNames.length > 0 && normType === NormType.Normalized && (
            <Popover style={{ display: 'flex' }} trigger={'hover'} content="Chemical calibration is active">
              <FontAwesomeIcon icon="balance-scale" style={{ marginRight: 10, fontSize: '13pt' }} />
            </Popover>
          )}
          {humidityCalibrationCalibrantName && (
            <Popover style={{ display: 'flex' }} trigger={'hover'} content="Humidity correction is active">
              <FontAwesomeIcon icon="droplet" style={{ marginRight: 10, fontSize: '13pt' }} />
            </Popover>
          )}
          <ArrowsAltOutlined className="clickable-icon" onClick={() => setIsVisibleModal(true)} />
        </Col>
      </Row>

      <MultiSignaturesFigure figureType={figureType} normType={normType} heatmapRangeValue={heatmapRangeValue} setHeatmapRangeValue={setHeatmapRangeValue} />

      <FullscreenGraphicModal title="Signatures" visible={isVisibleModal} onCancel={() => setIsVisibleModal(false)}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <MultiSignaturesFigure figureType={figureType} normType={normType} heatmapRangeValue={heatmapRangeValue} setHeatmapRangeValue={setHeatmapRangeValue} />
          <RecordsLegend />
        </div>
      </FullscreenGraphicModal>
    </div>
  );
};
