import { CheckCircleFilled, CheckCircleOutlined, QuestionCircleFilled, WarningFilled, WarningOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import tinycolor from 'tinycolor2';
import { AryRecord, PeptideSet } from '../types/analysisTypes';
import { defaultColorPalette } from './colormap';

export const ASCII_UPPERCASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const sortZippedArrays = (keys: (string | number)[], values: any[]): { key: any; value: any }[] => {
  var zipped: { key: string | number; value: any }[] = [];
  keys.forEach((k, i) => {
    zipped.push({
      key: k,
      value: values[i],
    });
  });
  zipped.sort((a, b) => (a.key < b.key ? -1 : 1));
  return zipped;
};

export const getGroupedMap = (keys: string[], values: any[]): Record<string, any[]> => {
  var zipped = sortZippedArrays(keys, values);

  const groupedMap: Record<string, any> = {};
  zipped.forEach(({ key }) => {
    groupedMap[key] = [];
  });
  zipped.forEach(({ key, value }) => {
    groupedMap[key].push(value);
  });
  return groupedMap;
};

export const getRecordNameShort = (r: Partial<AryRecord>) => {
  return `${r.ItemName} #${r.CycleNumber}`;
};

export const getRecordNameWithBr = (r: Partial<AryRecord>) => {
  return `${r.ItemName} #${r.CycleNumber}` + (r.ID !== undefined ? `<br>[No. ${r.ID + 1} ${r.AbsoluteTimestamp ? ' @ ' + new Date(r.AbsoluteTimestamp * 1e3).toLocaleString() : ''}]` : '');
};

export const getRecordNameWithBrB = (r: Partial<AryRecord>) => {
  return `<b>${r.ItemName}</b> #${r.CycleNumber}` + (r.ID !== undefined ? `<br>[No. ${r.ID + 1} ${r.AbsoluteTimestamp ? ' @ ' + new Date(r.AbsoluteTimestamp * 1e3).toLocaleString() : ''}]` : '');
};

export const getRecordNameOneLine = (r: Partial<AryRecord>) => {
  return `${r.ItemName} #${r.CycleNumber}` + (r.ID !== undefined ? ` [No. ${r.ID + 1} ${r.AbsoluteTimestamp ? ' @ ' + new Date(r.AbsoluteTimestamp * 1e3).toLocaleString() : ''}]` : '');
};

export const abs = (a: number[]): number[] => {
  return a.map((e) => Math.abs(e));
};

export const sum = (a: number[]): number => {
  return a.reduce((acc, cur) => {
    return (acc += cur);
  }, 0);
};

export const mean = (a: number[]): number => {
  return sum(a) / a.length;
};

export const variance = (a: number[]): number => {
  const avg = mean(a);
  const squareDiffs = a.map((cur) => Math.pow(cur - avg, 2));
  const avgSquareDiff = mean(squareDiffs);
  return avgSquareDiff;
};

export const standardDeviation = (a: number[]): number => Math.sqrt(variance(a));

export const euclideanDistance = (a: number[], b: number[]): number => {
  return Math.hypot(...a.map((e, i) => e - b[i]));
};

export const transpose = (a: number[][]): number[][] => {
  return a[0].map((col, i) => a.map((row) => row[i]));
};

export const argMax = (a: number[]) => {
  return a.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
};

export const meanAxis = (a: number[][], axis: number): number[] | number => {
  switch (axis) {
    case 0:
      return transpose(a).map((e) => mean(e)); // Mean along the cols (vertical) axis, e.g. meanAxis([[1,2,3], [1,2,3], [1,2,3]], null) -> [1, 2, 3]
    case 1:
      return a.map((e) => mean(e)); // Mean along the rows (horizontal) axis, e.g. meanAxis([[1,2,3], [1,2,3], [1,2,3]], null) -> [2, 2, 2]
    case null:
      return mean(a.map((e) => mean(e))); // Mean of the whole matrix, e.g. meanAxis([[1,2,3], [1,2,3], [1,2,3]], null) -> 2
    default:
      throw Error('Axis should be 0, 1 or null');
  }
};

export const shift = (a: number[][], baselineLength: number): number[][] => {
  const shifts = a.slice(0, baselineLength).map((row) => mean(row));
  return a.map((row) => row.map((col, i) => col - shifts[i]));
};

export const closeArray = (a: any[]) => {
  return a.concat(a.slice(0, 1));
};

export const min_max_norm = (row: number[]): number[] => {
  var min = Math.min(...row);
  var max = Math.max(...row);
  var numerator = row.map((cur) => cur - min);
  var denominator = max - min;
  return numerator.map((cur) => cur / denominator);
};

export const defaultPlotlyArguments = {
  layout: {
    autosize: true,
    showlegend: false,
    margin: {
      t: 30,
      b: 40,
      l: 40,
      r: 40,
    },
    polar: {
      angularaxis: {
        showticklables: false,
      },
      radialaxis: {
        showticklables: false,
        showgrid: false,
      },
    },
  } as Partial<Plotly.Layout>,
  config: {
    displaylogo: false,
    displayModeBar: false,
    responsive: true,
  } as Partial<Plotly.Config>,
  style: {
    width: '100%',
    height: '100%',
    margin: 'auto',
  },
};

export const median = (values: number[]) => {
  if (values.length === 0) return 0;

  values.sort((a, b) => {
    return a - b;
  });

  var half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];

  return (values[half - 1] + values[half]) / 2.0;
};

export const isMacOS = () => {
  return navigator.platform === 'MacIntel';
};

export const spots2peptides = (spots: string[]): [string[], [number, number][]] => {
  let peptideCodeStrs: string[] = [];
  let peptideCoordinateInts: [number, number][] = [];

  for (let i = 0; i < spots.length; i++) {
    let peptideFullStr = spots[i];
    let parts = peptideFullStr.split('[');
    if (parts.length === 2) {
      let [peptideCodeStr, peptideCoordinateStr] = parts;
      let [rowStr, colStr] = peptideCoordinateStr.split('');
      let rowInt = ASCII_UPPERCASE.indexOf(rowStr);
      let colInt = parseInt(colStr);

      peptideCodeStrs.push(peptideCodeStr);
      peptideCoordinateInts.push([rowInt, colInt]);
    } else {
      throw Error(`Error parsing peptide from a spot: ${peptideFullStr} (${parts})`);
    }
  }
  return [peptideCodeStrs, peptideCoordinateInts];
};

export const isSwLatest = (swVersion: string) => {
  return swVersion.match('2022.7.29') !== null;
};

export const isFwLatest = (fwVersion: string) => {
  return fwVersion.match('1.5.62') !== null;
};

export const setDifference = (setA: Set<number>, setB: Set<number>): Set<number> => {
  function _setDifference(setA: Set<number>, setB: Set<number>) {
    return new Set<number>(Array.from(setA).filter((element) => !setB.has(element)));
  }
  return new Set<number>([...Array.from(_setDifference(setA, setB)), ...Array.from(_setDifference(setB, setA))]);
};

export const spotsGrid2PeptideSet = (spotsGrid: number[], isProcessedSpotgrid?: boolean): Set<number> => {
  if (isProcessedSpotgrid) return new Set(spotsGrid.filter((s) => s > 1));
  return new Set(spotsGrid.map((s) => Math.floor(s / 10)).filter((s) => s > 1));
};

export const getPeptideSetType = (spotsGrid: number[], isProcessedSpotgrid?: boolean): PeptideSet => {
  if (spotsGrid === null || spotsGrid === undefined) {
    return PeptideSet.Unknown;
  }
  if (spotsGrid.length === 0) {
    return PeptideSet.Unknown;
  }

  const POR1Set = new Set([19, 55, 27, 28, 63, 30, 62, 66, 25, 22, 20, 23, 29, 26]);
  const POR2Set = new Set([24, 18, 19, 21, 55, 27, 28, 63, 30, 62, 66, 25, 22, 20, 23, 29, 26, 54, 64, 65]);
  let uniquePeptides = spotsGrid2PeptideSet(spotsGrid, isProcessedSpotgrid);

  if (setDifference(POR1Set, uniquePeptides).size === 0) {
    return PeptideSet.POR1;
  }
  if (setDifference(POR2Set, uniquePeptides).size === 0) {
    return PeptideSet.POR2;
  }
  return PeptideSet.NonStandard;
};

export const renderPeptideSetType = (peptideSetType: PeptideSet) => {
  switch (peptideSetType) {
    case PeptideSet.POR1:
      return <Tag color="blue">POR 1</Tag>;
    case PeptideSet.POR2:
      return <Tag color="green">POR 2</Tag>;
    case PeptideSet.Unknown:
      return (
        <>
          <Tag color="orange">Unknown</Tag>
        </>
      );
    case PeptideSet.NonStandard:
      return (
        <>
          <Tag color="orange">Non-standard</Tag>
        </>
      );
    default:
      return <QuestionCircleFilled style={{ color: defaultColorPalette['orange'] }} />;
  }
};

export const renderSwVersion = (swVersion: string) => {
  if (!swVersion) {
    return null;
  }

  const reDate = /([\d]{4}).([\d]{1,2}).([\d]{1,2})/;
  const match = swVersion.match(reDate);

  if (!match) {
    return null;
  }

  const [_, yr, mo, da] = Object.values(match);
  if (!yr || !mo || !da) {
    return null;
  }

  const d = new Date();
  d.setFullYear(parseInt(yr));
  d.setMonth(parseInt(mo));
  d.setDate(parseInt(da));

  const recentDate = new Date(2022, 11, 16);
  const previousDate = new Date(2022, 7, 29);

  if (d >= recentDate) {
    return (
      <Tooltip overlay={`SW is up to date: ${swVersion}`}>
        <CheckCircleFilled style={{ color: defaultColorPalette['green'] }} />
      </Tooltip>
    );
  }
  if (d >= previousDate) {
    return (
      <Tooltip overlay={`SW is not latest but OK: ${swVersion}`}>
        <CheckCircleOutlined style={{ color: defaultColorPalette['green'] }} />
      </Tooltip>
    );
  }
  return (
    <Tooltip overlay={`SW Version is outdated: ${swVersion}`}>
      <WarningOutlined style={{ color: defaultColorPalette['orange'] }} />
    </Tooltip>
  );
};

export const renderFwVersion = (fwVersion: string) => {
  if (!fwVersion) {
    return null;
  }
  if (fwVersion.localeCompare('1.5') >= 0) {
    return (
      <Tooltip overlay={`FW is up to date (Gen1.5): ${fwVersion}`}>
        <CheckCircleFilled style={{ color: defaultColorPalette['green'] }} />
      </Tooltip>
    );
  }
  if (fwVersion.localeCompare('1.3') < 0) {
    return (
      <Tooltip overlay={`FW is outdated (Gen1.0): ${fwVersion}`}>
        <WarningOutlined style={{ color: defaultColorPalette['orange'] }} />
      </Tooltip>
    );
  }
};

export const renderDeviceID = (deviceID?: string) => {
  if (!deviceID) {
    return null;
  }
  let m = deviceID.match(/(?<noa>NOA.*?-)(?<shellSerial>.*?)-(?<cs>CS.*?-){0,1}(?<coreSensorSerial>.*?)_fsp/i);
  if (!m) {
    return deviceID;
  } else {
    return (
      <>
        {m.groups?.['noa']}
        <b>{m.groups?.['shellSerial']}</b>-{m.groups?.['cs']}
        <b>{m.groups?.['coreSensorSerial']}</b>_fsp
      </>
    );
  }
};

export const renderExposureIcon = (exposure: number) => {
  if (exposure === 0) {
    return null;
  }
  if (exposure > 100 && exposure < 10000) {
    return (
      <Tooltip overlay={`Correct exposure: ${exposure}`}>
        <CheckCircleFilled style={{ color: defaultColorPalette['green'] }} />
      </Tooltip>
    );
  }
  if (exposure < 100) {
    return (
      <Tooltip overlay={`Sensor is underexposed: ${exposure}μs. Probably maintenance is required`}>
        <WarningFilled style={{ color: defaultColorPalette['orange'] }} />
      </Tooltip>
    );
  }
  if (exposure > 10000) {
    return (
      <Tooltip overlay={`Sensor is overexposed: ${exposure}μs. Probably maintenance is required`}>
        <WarningFilled style={{ color: defaultColorPalette['orange'] }} />
      </Tooltip>
    );
  }
};

export const renderTimeDuration = (d1: Date, d2: Date) => {
  let yearsDiff = d2.getFullYear() - d1.getFullYear();
  let monthsDiff = d2.getMonth() - d1.getMonth();
  let daysDiff = d2.getDate() - d1.getDate();
  let hoursDiff = d2.getHours() - d1.getHours();
  let minutesDiff = d2.getMinutes() - d1.getMinutes();
  let secondsDiff = d2.getSeconds() - d1.getSeconds();
  let renderedDuration = '';
  if (yearsDiff > 0) {
    renderedDuration += `${yearsDiff}yr `;
    return renderedDuration;
  }
  if (monthsDiff > 0) {
    renderedDuration += `${monthsDiff}mo `;
    return renderedDuration;
  }
  if (daysDiff > 0) {
    renderedDuration += `${daysDiff}d `;
    return renderedDuration;
  }
  if (hoursDiff > 0) {
    renderedDuration += `${hoursDiff}hr `;
    return renderedDuration;
  }
  if (minutesDiff > 0) {
    renderedDuration += `${minutesDiff}min `;
    return renderedDuration;
  }
  if (secondsDiff > 0) {
    renderedDuration += `${secondsDiff}s `;
    return renderedDuration;
  }
  renderedDuration = 'just now';
  return renderedDuration;
};

export const renderTimestamp = (timestamp: string | number | Date) => {
  return (
    <span>
      <Tag>{`${new Date(timestamp).toLocaleDateString('fr-FR')} ${new Date(timestamp).toLocaleTimeString('fr-FR')}`}</Tag>
    </span>
  );
};

export const getSortedUniqueSlice = (aa: string[][]): string[] => {
  let uniqueSet: Set<string> = new Set();
  aa.forEach((a) => {
    a.forEach((s) => {
      uniqueSet.add(s);
    });
  });
  let uniqueSlice: string[] = Array.from(uniqueSet);
  uniqueSlice.sort();
  return uniqueSlice;
};

export const renderColoredTag = (colorString: string, children: JSX.Element | null): JSX.Element | null => {
  const mainColor = tinycolor(colorString);
  const transparentColor = tinycolor(colorString).setAlpha(0.2);
  const darkenedColor = tinycolor(colorString).darken(5);
  return (
    <Tag
      className="tag-multilign"
      style={{
        color: darkenedColor.toHexString(),
        fontWeight: 400,
        border: `1px solid ${mainColor.toHex8String()}`,
        backgroundColor: transparentColor.toPercentageRgbString(),
        marginBottom: 5,
      }}
    >
      {children}
    </Tag>
  );
  return null;
};
