import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOktaAuth } from '@okta/okta-react/';
import { Alert, Popover } from 'antd';
import { CSSProperties, Key, useEffect, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { arycolor } from '../../../assets/css/color';
import { aryballeColorPalette, DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE } from '../../../compute/colormap';
import { getPeptideSetType, spots2peptides } from '../../../compute/utils';
import { selectAggregatePeptides, selectBoundariesMap, selectCurrentRecordID, selectExcludedRecordIDs, selectRecords, selectSessionID, selectSubtractItemName } from '../../../features/analysisConfig/analysisConfigSlice';
import { PeptideSet, Signature } from '../../../types/analysisTypes';
import { FeatureFlag, UserClaimsWithTSDB } from '../../../types/userType';
import { fetchAuthorizedAPIEndpoint } from '../../../utils';
import PeptidesSelectorGrid from './PeptidesSelector/PeptidesSelectorGrid';
import SpotsSelectorGrid from './PeptidesSelector/SpotsSelectorGrid';

type PeptidesSelectorSettingsProps = {};

type typeStyle = {
  [K in Key]: CSSProperties;
};

enum PeptidesState {
  SelectedPeptides = 'Selected',
  UnselectedPeptides = 'Unselected',
  UnselectedSomeSpots = 'Partially selected',
  InactivePeptides = 'Inactive',
}

const PeptidesSelectorSettings: React.FC<PeptidesSelectorSettingsProps> = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(true);

  const sessionID = useAppSelector(selectSessionID);
  const currentRecordID = useAppSelector(selectCurrentRecordID);
  const records = useAppSelector(selectRecords);
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const boundariesMap = useAppSelector(selectBoundariesMap);
  const subtractItemName = useAppSelector(selectSubtractItemName);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);

  const [error, setError] = useState('');
  const [formatedSpots, setFormatedSpots] = useState<{ X: number[]; Y: number[]; Z: string[][] }>();
  const [spots, setSpots] = useState<String[]>();
  const [peptidesSetType, setPeptidesSetType] = useState<PeptideSet>(PeptideSet.Unknown);

  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    var boundaries = boundariesMap[currentRecordID];
    if (boundaries === undefined) {
      return;
    }
    if (!userInfo) return;
    fetchAuthorizedAPIEndpoint(`/compute/signature?session_id=${sessionID}&record_id=${currentRecordID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        boundariesMap,
        aggregatePeptides: !(userInfo && userInfo.feature_flags.includes(FeatureFlag.AANonAggregatedPeptidesAccessEnabled)),
        subtractItemName,
        excludedRecordIDs,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((data: Signature) => {
        setError('');
        if (aggregatePeptides === false) setFormatedSpots(formatSpots(data.Peptides));
        else setSpots(data.Peptides);

        if (data.Peptides) {
          setPeptidesSetType(
            getPeptideSetType(
              data.Peptides.map((p) => Number(parseInt(p.toString()))),
              true
            )
          );
        } else setPeptidesSetType(PeptideSet.Unknown);
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { Reason: string }) => {
          setError(resp.Reason);
        });
      });
  }, [authState, sessionID, currentRecordID, boundariesMap, aggregatePeptides, records, subtractItemName, excludedRecordIDs]);

  const formatSpots = (spots: string[]) => {
    let [peptideCodeStrs, peptideCoordinateInts] = spots2peptides(spots);
    let rowNb = Math.max(...peptideCoordinateInts.map((e) => e[0]));
    let colNb = Math.max(...peptideCoordinateInts.map((e) => e[1]));

    let X: number[] = [];
    let Y: number[] = [];
    let Z: string[][] = [];

    for (let colIdx = 0; colIdx < colNb + 1; colIdx++) X.push(colIdx);
    for (let rowIdx = 0; rowIdx < rowNb + 1; rowIdx++) Y.push(rowIdx);

    for (let rowIdx = 0; rowIdx < rowNb + 1; rowIdx++) {
      let row: string[] = [];
      for (let colIdx = 0; colIdx < colNb + 1; colIdx++) {
        row.push('');
      }
      Z.push(row);
    }
    peptideCoordinateInts.forEach(([row, col], i) => (Z[row][col] = peptideCodeStrs[i]));
    return { X, Y, Z };
  };

  if (error !== '') {
    return (
      <div>
        <Alert type="error" message={error} />
      </div>
    );
  }

  const style: typeStyle = {
    base: { width: 50, height: 32, textAlign: 'center', lineHeight: '32px' },
    baseLegend: { width: 40, height: 27, textAlign: 'center', lineHeight: '27px', color: 'white', background: aryballeColorPalette.cyan, fontSize: 14 },
    th: { color: 'black', fontWeight: 500 },
    td: { background: aryballeColorPalette.cyan, cursor: 'pointer', color: 'white' },
    peptidesBroken: { background: '#EFEFEF', cursor: 'initial', border: '1px solid #EFEFEF' },
    selectedPeptides: { background: DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, border: `1px solid ${DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE}` },
    diselectedPeptides: { background: 'white', border: '1px solid #CCD0D4', color: aryballeColorPalette.blueDark, fontWeight: 500 },
    diselectedSomePeptides: { background: 'white', border: `2px solid ${DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE}`, color: DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, fontWeight: 700, minWidth: '40px' },
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{aggregatePeptides ? 'Peptides' : 'Spots'} selector</span>
        <Popover
          style={{ display: 'flex' }}
          trigger={'hover'}
          content={
            <div>
              Permits to control the <b>biosensor set</b> of the data.
              <br></br>
              Note: <b>no peptide nor spot alignment</b> is done automatically in case of datasets with different PORs
              <br></br>
              For now it is the user's responsibility to respect the spotfile
            </div>
          }
        >
          <FontAwesomeIcon
            icon="info-circle"
            className="clickable-icon"
            style={{
              color: arycolor.aryBlue,
              marginLeft: 20,
            }}
          />
        </Popover>
        {isDropdownOpen ? (
          <FontAwesomeIcon icon="caret-down" style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => setIsDropdownOpen(false)} />
        ) : (
          <FontAwesomeIcon icon="caret-right" style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => setIsDropdownOpen(true)} />
        )}
      </div>

      {isDropdownOpen && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {!aggregatePeptides ? <SpotsSelectorGrid style={style} spots={formatedSpots} peptidesSetType={peptidesSetType} /> : <PeptidesSelectorGrid style={style} spots={spots} peptidesSetType={peptidesSetType} />}

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 32 }}>
            <div style={{ display: 'flex', margin: 2.5, alignItems: 'center' }}>
              <td style={{ ...style.baseLegend, ...style.selectedPeptides }}>12</td>
              <span style={{ marginLeft: 15 }}>{PeptidesState.SelectedPeptides}</span>
            </div>
            <div style={{ display: 'flex', margin: 2.5, alignItems: 'center' }}>
              <td style={{ ...style.baseLegend, ...style.diselectedPeptides }}>31</td>
              <span style={{ marginLeft: 15 }}>{PeptidesState.UnselectedPeptides}</span>
            </div>
            {aggregatePeptides && (
              <div style={{ display: 'flex', margin: 2.5, alignItems: 'center' }}>
                <td style={{ ...style.baseLegend, ...style.diselectedSomePeptides }}>31</td>
                <span style={{ marginLeft: 15 }}>{PeptidesState.UnselectedSomeSpots}</span>
              </div>
            )}
            <div style={{ display: 'flex', margin: 2.5, alignItems: 'center' }}>
              <td style={{ ...style.baseLegend, ...style.peptidesBroken }}></td>
              <span style={{ marginLeft: 15 }}>{PeptidesState.InactivePeptides}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PeptidesSelectorSettings;
