import { Alert, Col, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  selectCurrentRecordID,
  selectExcludedPeptides,
  selectExcludedSpots,
  selectRecords,
  setAggregatePeptides,
  setBoundariesMap,
  setChemicalCalibrationItemNames,
  setExcludedPeptides,
  setExcludedRecordIDs,
  setExcludedSpots,
  setHumidityCompensationCalibrantName,
  setHumidityCompensationPositionOffset,
  setHumidityCompensationSubstractionGain,
  setPCAEigenvalues,
  setPCAEigenvectors,
  setSessionID,
  setSubtractItemName,
} from '../../features/analysisConfig/analysisConfigSlice';
import { AnalysisConfig, AryRecord, PeptideSet } from '../../types/analysisTypes';
import SectionPage from '../../components/section/SectionPage';
import DashboardMain from './DashboardMain';
import DashboardSelect from './dashbordRecords/DashbordSelect';
import DashboardSettings from './dashboardSettings/DashboardSettings';
import { selectVisibleSettings } from '../../features/analysisConfig/sessionInfoSlice';
import { fetchAuthorizedAPIEndpoint } from '../../utils';
import { useOktaAuth } from '@okta/okta-react';
import { resetPdfConfig } from '../../features/analysisConfig/pdfSlice';
import { LoadingOutlined } from '@ant-design/icons';
import { getPeptideSetType } from '../../compute/utils';
import { FeatureFlag, UserClaimsWithTSDB } from '../../types/userType';

const Stopwatch = () => {
  const [time, setTime] = useState(0);
  useEffect(() => {
    var interval: NodeJS.Timeout | undefined = undefined;
    interval = setInterval(() => {
      setTime((prevTime) => prevTime + 100);
    }, 100);
    return () => {
      interval && clearInterval(interval);
    };
  }, []);

  const minutes = Math.floor((time / 60000) % 60);
  const seconds = Math.floor((time / 1000) % 60);
  const milliseconds = (time / 10) % 100;

  if (minutes >= 1 || seconds >= 5) {
    return (
      <div className="stopwatch" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span>Loading takes more time than usual. Please wait..</span>
        <div className="numbers">
          {minutes >= 1 && <span>{minutes.toFixed(0).padStart(2)}:</span>}
          <span>{seconds.toFixed(0).padStart(2, '0')}:</span>
          <span>{milliseconds.toFixed(0).padStart(2, '0')}</span>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export const DashboardPage: FC = () => {
  const { sessionID } = useParams<{ sessionID: string }>();

  const [analysisConfig, setAnalysisConfig] = useState<AnalysisConfig>();
  const [isExpendedMenu, setIsExpandedMenu] = useState<boolean>(true);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const records = useAppSelector(selectRecords);
  const currentRecordID = useAppSelector(selectCurrentRecordID);

  const [peptidesSetType, setPeptidesSetType] = useState<PeptideSet>(PeptideSet.Unknown);

  const visibleSettings = useAppSelector(selectVisibleSettings);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);

  const { authState } = useOktaAuth();

  const dispatch = useAppDispatch();

  // set analysis config
  useEffect(() => {
    if (sessionID !== '') {
      fetchAuthorizedAPIEndpoint(`/load_config?session_id=${sessionID}`, authState)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            resp.json().then((e: { Reason: string }) => {
              setError(`${resp.status} ${resp.statusText}: ${e.Reason}`);
            });
          }
        })
        .then((ac: AnalysisConfig) => {
          if (!ac) {
            return;
          }
          setAnalysisConfig(ac);
          if (ac.AggregatePeptides !== null) {
            dispatch(setAggregatePeptides(ac.AggregatePeptides));
          }
          if (ac.SubtractItemName !== null) {
            dispatch(setSubtractItemName(ac.SubtractItemName));
          }
          if (ac.PCAEigenvalues !== null) {
            dispatch(setPCAEigenvalues(ac.PCAEigenvalues));
          }
          if (ac.PCAEigenvectors !== null) {
            dispatch(setPCAEigenvectors(ac.PCAEigenvectors));
          }
          if (ac.ExcludedRecordIDs !== null) {
            dispatch(setExcludedRecordIDs(ac.ExcludedRecordIDs));
          }
          if (ac.BoundariesMap !== null) {
            dispatch(setBoundariesMap(ac.BoundariesMap));
          }
          if (ac.SessionID !== null) {
            dispatch(setSessionID(ac.SessionID));
          }
          if (ac.ExcludedPeptides !== null) {
            dispatch(setExcludedPeptides(ac.ExcludedPeptides));
          }
          if (ac.ExcludedSpots !== null) {
            dispatch(setExcludedSpots(ac.ExcludedSpots));
          }
          if (ac.HumidityCompensation && ac.HumidityCompensation.CalibrantName) {
            dispatch(setHumidityCompensationCalibrantName(ac.HumidityCompensation.CalibrantName));
          }
          if (ac.HumidityCompensation && ac.HumidityCompensation.PositionOffset) {
            dispatch(setHumidityCompensationPositionOffset(ac.HumidityCompensation.PositionOffset));
          }
          if (ac.HumidityCompensation && ac.HumidityCompensation.SubstractionGain) {
            dispatch(setHumidityCompensationSubstractionGain(ac.HumidityCompensation.SubstractionGain));
          }
          if (ac.ChemicalCalibrationItemNames !== undefined && ac.ChemicalCalibrationItemNames !== null) {
            dispatch(setChemicalCalibrationItemNames(ac.ChemicalCalibrationItemNames));
          }
          setIsLoading(false);
        })
        .catch((e: Error) => {
          setError(e.message);
        });

      dispatch(resetPdfConfig());
    }
  }, [sessionID]);

  // set user info
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState]);

  // set PeptidesSetType
  useEffect(() => {
    if (records && currentRecordID !== undefined) {
      const record = records?.find((record: AryRecord) => record.ID === currentRecordID);

      if (record) {
        const spotgrid = [...record.Sensors.map((s) => parseInt(s))]; // parseInt tranform peptides with coordonate to number ex: 134[A3] = 134
        setPeptidesSetType(getPeptideSetType(spotgrid, true));
      } else setPeptidesSetType(PeptideSet.Unknown);
    }
  }, [currentRecordID, records]);

  // exlude peptide and spots 1 by default
  useEffect(() => {
    if (!records || !userInfo) return;
    const record = records?.find((record: AryRecord) => record.ID === currentRecordID);
    if (!record) return;

    if (peptidesSetType === PeptideSet.POR1 || peptidesSetType === PeptideSet.POR2) {
      if (userInfo && userInfo.feature_flags.includes(FeatureFlag.AANonAggregatedPeptidesAccessEnabled)) {
        dispatch(setExcludedSpots(Array.from(new Set([...excludedSpots, ...record.Sensors.filter((s) => parseInt(s) === 1).map((s) => s.toString().split('[')[1].split(']')[0])]))));
      } else {
        dispatch(setExcludedPeptides(Array.from(new Set([...excludedPeptides, '1']))));
      }
    }
  }, [currentRecordID, dispatch, peptidesSetType, records, userInfo]);

  // retrocompatibility of excludepeptides with old session
  // TODO: obselete code when all sessions used excluded peptides with non-agreggate right will be reload for update
  useEffect(() => {
    if (!records || !userInfo) return;
    const record = records?.find((record: AryRecord) => record.ID === currentRecordID);
    if (!record) return;

    if (userInfo && userInfo.feature_flags.includes(FeatureFlag.AANonAggregatedPeptidesAccessEnabled)) {
      if (excludedPeptides && excludedPeptides.length !== 0) {
        const matchingSpot = excludedPeptides
          .map((peptide) => record.Sensors.filter((s) => parseInt(s) === Number(peptide)))
          .flat()
          .map((s) => s.toString().split('[')[1].split(']')[0]);

        dispatch(setExcludedSpots(Array.from(new Set([...excludedSpots, ...matchingSpot]))));
        dispatch(setExcludedPeptides([]));
      }
    }
  }, [currentRecordID, dispatch, userInfo, records]);

  useEffect(() => {
    if (visibleSettings === true) setIsExpandedMenu(false);
    else setIsExpandedMenu(true);
  }, [visibleSettings]);

  if (error !== '') {
    return (
      <>
        <Row justify="center" style={{ marginTop: '10vh' }}>
          <Col>
            <Alert showIcon={true} style={{ textAlign: 'left' }} type="error" message={error}></Alert>
          </Col>
        </Row>
      </>
    );
  }

  if (isLoading) {
    return (
      <SectionPage boxShadow={false} height={`calc(100vh - 120px)`}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <LoadingOutlined />
          <p>Please waiting, we load your session.</p>
          <Stopwatch />
        </div>
      </SectionPage>
    );
  }

  if (analysisConfig === undefined) {
    return null;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <SectionPage backgroundColor="transparent" boxShadow={false} width={visibleSettings ? 'calc(100% - 400px)' : '100%'}>
        <Row gutter={[20, 20]} id="dashbord">
          <DashboardSelect isExpendedMenu={isExpendedMenu} setIsExpandedMenu={setIsExpandedMenu} />
          <DashboardMain isExpendedMenu={isExpendedMenu} />
        </Row>
      </SectionPage>
      <DashboardSettings />
    </div>
  );
};
