import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Popover } from 'antd';
import { CSSProperties, Key } from 'react';
import { Link } from 'react-router-dom';

type ExportsProps = {
  sessionID: string;
};

type typeStyle = {
  [K in Key]: CSSProperties;
};

enum ItemMenu {
  ExportPDF = 'Generate PDF report',
  ExportCSV = 'Export CSV data',
}

const Exports: React.FC<ExportsProps> = ({ sessionID }) => {
  const style: typeStyle = {
    li: { height: 25, lineHeight: '25px' },
  };

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      overlayInnerStyle={{ borderRadius: 5 }}
      title={null}
      content={
        <Menu
          style={{ borderRight: 'none', marginLeft: -10, marginRight: -10 }}
          items={[
            {
              label: <Link to={`/dashboard/${sessionID}/pdf`}>{ItemMenu.ExportPDF}</Link>,
              key: ItemMenu.ExportPDF,
              style: style.li,
            },
            {
              label: (
                <Link to={`/dashboard/${sessionID}/csv`} target={'_blank'}>
                  {ItemMenu.ExportCSV}
                </Link>
              ),
              key: ItemMenu.ExportCSV,
              style: style.li,
            },
          ]}
        />
      }
    >
      <FontAwesomeIcon icon="file-export" style={{ width: 20, height: 20, cursor: 'pointer', marginRight: 10 }} />
    </Popover>
  );
};

export default Exports;
