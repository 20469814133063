import { Drawer } from 'antd';
import { CSSProperties, Key } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectVisibleSettings, setVisibleSettings } from '../../../features/analysisConfig/sessionInfoSlice';
import { FeatureFlag } from '../../../types/userType';
import { WithFeatureFlagsCheck } from '../../../with_feature_flags_check';
import AggregatePeptidesSettings from './AggregatePeptidesSettings';
import ChemicalCalibrationSettings from './ChemicalCalibrationSettings';
import HumidityCompensationSettings from './HumidityCompensationSettings';
import PeptidesSelectorSettings from './PeptidesSelectorSettings';
import SigmaMultiplierSettings from './SigmaMultiplierSettings';
import SubstractItemSettings from './SubstractItemSettings';

type DashboardSettingsProps = {};

type typeStyle = {
  [K in Key]: CSSProperties;
};

const DashboardSettings: React.FC<DashboardSettingsProps> = () => {
  const visibleSettings = useAppSelector(selectVisibleSettings);
  const dispatch = useAppDispatch();
  const style: typeStyle = {
    container: { position: 'relative', height: '100vh', width: visibleSettings ? 400 : 0, padding: visibleSettings ? 48 : 0, overflow: 'hidden', textAlign: 'center' },
  };

  return (
    <div style={style.container}>
      <Drawer
        title="Dashboard settings"
        placement="right"
        visible={visibleSettings}
        getContainer={false}
        bodyStyle={{ overflowY: 'scroll', textAlign: 'left', paddingBottom: 50 }}
        style={{ position: 'fixed', right: 0, top: 50, width: style.container.width, height: 'calc(100% - 50px)' }}
        headerStyle={{ borderBottom: 'none' }}
        mask={false}
        onClose={() => dispatch(setVisibleSettings(false))}
      >
        <WithFeatureFlagsCheck ff={FeatureFlag.AANonAggregatedPeptidesAccessEnabled}>
          <AggregatePeptidesSettings />
        </WithFeatureFlagsCheck>
        <div style={{ height: '30px' }}></div>
        <HumidityCompensationSettings />
        <div style={{ height: '30px' }}></div>
        <WithFeatureFlagsCheck ff={FeatureFlag.AAChemicalCalibrationAccessEnabled}>
          <ChemicalCalibrationSettings />
        </WithFeatureFlagsCheck>
        <div style={{ height: '30px' }}></div>
        <PeptidesSelectorSettings />
        <div style={{ height: '30px' }}></div>
        <SubstractItemSettings />
        <div style={{ height: '30px' }}></div>
        <SigmaMultiplierSettings />
        <div style={{ height: '60px' }}></div>
      </Drawer>
    </div>
  );
};

export default DashboardSettings;
