import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Popover, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { arycolor } from '../../../assets/css/color';
import SliderComponent from '../../../components/slider/Slider';
import {
  selectExcludedRecordIDs,
  selectHumidityCompensationCalibrantName,
  selectHumidityCompensationPositionOffset,
  selectHumidityCompensationSubstractionGain,
  selectRecords,
  selectSessionID,
  setHumidityCompensationCalibrantName,
  setHumidityCompensationPositionOffset,
  setHumidityCompensationSubstractionGain,
} from '../../../features/analysisConfig/analysisConfigSlice';
import { WithFeatureFlagsCheck } from '../../../with_feature_flags_check';
import { FeatureFlag } from '../../../types/userType';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';
import { RunMetadata } from '../../../types/runsType';
import { HumidityCalibrantCheck, HumidityWarningCode } from '../../../types/analysisTypes';

type HumidityCompensationSettingsProps = {};

const HumidityCompensationSettings: React.FC<HumidityCompensationSettingsProps> = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const records = useAppSelector(selectRecords);

  const humidityCompensationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCompensationSubstractionGain = useAppSelector(selectHumidityCompensationSubstractionGain);
  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);

  const [humidityCalibrationIntermediatePositionOffset, setHumidityCalibrationIntermediatePositionOffset] = useState<number>(humidityCompensationPositionOffset);
  const [humidityCalibrationIntermediateSubstractionGain, setHumidityCalibrationIntermediateSubstractionGain] = useState<number>(humidityCompensationSubstractionGain);

  const excludedRecordIDs = useAppSelector<number[]>(selectExcludedRecordIDs);

  const { authState } = useOktaOrQueryAuth();
  const [isActiveWarningAmplifier, setActiveWarningAmplifier] = useState<boolean>(false);
  const [isActiveWarningWater, setActiveWarningWater] = useState<HumidityCalibrantCheck>();
  const [warningWaterMessage, setWarningWaterMessage] = useState<string>();
  const sessionID = useAppSelector(selectSessionID);

  const uniqueItemNames = Array.from(new Set(records?.map((r) => r.ItemName)));
  uniqueItemNames.sort();

  // Load runs
  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    if (sessionID !== '') {
      fetchAuthorizedAPIEndpoint(`/runs?session_id=${sessionID}`, authState)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            throw new Error();
          }
        })
        .then((runs: RunMetadata[]) => {
          if (runs != null) {
            setActiveWarningAmplifier(
              runs
                .map((run) => run.Tags)
                .flat()
                .includes('$amplifier')
            );
          }
        });
    }
  }, [authState, sessionID]);

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    if (!humidityCalibrationCalibrantName) return;
    fetchAuthorizedAPIEndpoint(`/checks/humidity_calibrant?session_id=${sessionID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        excludedRecordIDs,
        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCompensationPositionOffset,
          SubstractionGain: humidityCompensationSubstractionGain,
        },
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((humidityCalibrantCheck: HumidityCalibrantCheck) => {
        setActiveWarningWater(humidityCalibrantCheck);
        if (humidityCalibrantCheck && !humidityCalibrantCheck.Success && humidityCalibrantCheck.Code) {
          if (humidityCalibrantCheck.Code === HumidityWarningCode.ERR_HCAL_ALL_UNSELECTED) setWarningWaterMessage('Humidity calibrant item has no active measure');
          else if (humidityCalibrantCheck.Code === HumidityWarningCode.WARN_HCAL_TIME_GAP) setWarningWaterMessage('Time gap between Water measure and experiment is greater than 24h');
        }
      });
  }, [authState, sessionID, humidityCalibrationCalibrantName, excludedRecordIDs, humidityCompensationPositionOffset, humidityCompensationSubstractionGain]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <WithFeatureFlagsCheck ff={FeatureFlag.AAHumidityCompensationAccessEnabled}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span>Humidity Compensation</span>
            <Popover
              content={
                <>
                  Compensate <b>humidity</b> impact on samples by <b>subtracting</b> humidity calibrant's <b>signature</b>, weighted on <b>humidity delta</b> values of each record.
                </>
              }
              overlayStyle={{ maxWidth: '500px' }}
              placement="left"
            >
              <FontAwesomeIcon
                icon="info-circle"
                className="clickable-icon"
                style={{
                  color: arycolor.aryBlue,
                  marginLeft: 20,
                }}
              />
            </Popover>
            {isDropdownOpen ? (
              <FontAwesomeIcon icon="caret-down" style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => setIsDropdownOpen(false)} />
            ) : (
              <FontAwesomeIcon icon="caret-right" style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => setIsDropdownOpen(true)} />
            )}
          </div>
        </WithFeatureFlagsCheck>
        {isDropdownOpen && (
          <div>
            <WithFeatureFlagsCheck ff={FeatureFlag.AAHumidityCompensationAccessEnabled}>
              <div style={{ width: '100%' }}>
                {isActiveWarningAmplifier && <Alert message="An amplifier has been used, humidity correction should not be applied" type="warning" showIcon style={{ borderRadius: 5, marginTop: 5 }} />}
                {isActiveWarningWater && !isActiveWarningWater?.Success && warningWaterMessage && <Alert message={warningWaterMessage} showIcon style={{ borderRadius: 5, marginTop: 5 }} />}
                <Select
                  style={{ width: '100%', marginTop: 10 }}
                  value={humidityCalibrationCalibrantName ? humidityCalibrationCalibrantName : undefined}
                  showSearch={true}
                  placeholder="Select humidity calibrant item.."
                  allowClear={true}
                  options={uniqueItemNames.map((uin) => ({
                    label: uin,
                    value: uin,
                  }))}
                  onChange={(value: string) => {
                    if (value === undefined) {
                      value = '';
                    }
                    dispatch(setHumidityCompensationCalibrantName(value));
                  }}
                />
              </div>
            </WithFeatureFlagsCheck>
            <>
              <WithFeatureFlagsCheck ff={FeatureFlag.RoleAryballe}>
                <div style={{ width: '100%' }}>
                  <div style={{ marginLeft: '1vw', marginTop: 20 }}>
                    <span>Humidity reading offset, frames</span>
                    <Popover
                      content={
                        <>
                          By default, the <b>maximum humidity</b> value over the whole record is taken as a <b>reference for humidity subtraction weighting</b>
                          <br />
                          <br />
                          You can <b>override</b> the automatically detected reference point by "moving" the reference point left (earlier) or right (later). Central (0) value represents the point of maximum humidity.
                          <br />
                          <br />
                          You can use individual records' sensogram charts for fine control of overridden values.
                          <br />
                          <br />
                          <b>Note</b> that the <b>same offset</b> override is applied to <b>all samples</b>, but the shift is <b>relative</b> to some initial point detected for each record individually.
                        </>
                      }
                      overlayStyle={{ maxWidth: '500px' }}
                      placement="left"
                    >
                      <FontAwesomeIcon
                        icon="info-circle"
                        className="clickable-icon"
                        style={{
                          color: arycolor.aryBlue,
                          marginLeft: 20,
                        }}
                      />
                    </Popover>
                  </div>
                  <SliderComponent
                    min={-300}
                    max={300}
                    value={humidityCalibrationIntermediatePositionOffset}
                    style={{ marginTop: 5, marginLeft: '50px', marginRight: '50px' }}
                    marks={{
                      '-300': '-300',
                      '0': '0',
                      '300': '300',
                    }}
                    onAfterChange={(value) => {
                      dispatch(setHumidityCompensationPositionOffset(value));
                    }}
                    onChange={(value) => {
                      setHumidityCalibrationIntermediatePositionOffset(value);
                    }}
                  />
                </div>
              </WithFeatureFlagsCheck>
              <WithFeatureFlagsCheck ff={FeatureFlag.RoleAryballe}>
                <div style={{ width: '100%' }}>
                  <div style={{ marginLeft: '1vw', marginTop: 20 }}>
                    <span>Humidity subtraction gain, k</span>
                    <Popover
                      content={
                        <>
                          By default, humidity signature is subtracted from each sample in an amount proportional to samples' ΔH. <b>Gain</b> allows to adjust the amount of humidity subtraction to account for non-linear and special cases.
                          <br />
                          <br />
                          The default gain coefficient is 1 which corresponds to the default amount. To lower compensation, set the gain somewhere from 0 to 1. To increase the compensation, set it above 1. You can only gain the compensation up to
                          twice the default amount (2).
                        </>
                      }
                      overlayStyle={{ maxWidth: '500px' }}
                      placement="left"
                    >
                      <FontAwesomeIcon
                        icon="info-circle"
                        className="clickable-icon"
                        style={{
                          color: arycolor.aryBlue,
                          marginLeft: 20,
                        }}
                      />
                    </Popover>
                  </div>
                  <SliderComponent
                    min={0}
                    max={2}
                    step={0.01}
                    value={humidityCalibrationIntermediateSubstractionGain}
                    marks={{
                      '0': '0',
                      '1': '1',
                      '2': '2',
                    }}
                    style={{ marginTop: 5, marginLeft: '50px', marginRight: '50px' }}
                    onAfterChange={(value) => {
                      dispatch(setHumidityCompensationSubstractionGain(value));
                    }}
                    onChange={(value) => {
                      setHumidityCalibrationIntermediateSubstractionGain(value);
                    }}
                  />
                </div>
              </WithFeatureFlagsCheck>
            </>
          </div>
        )}
      </div>
    </>
  );
};

export default HumidityCompensationSettings;
